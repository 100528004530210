import React from 'react';

import { PATHS } from 'Routes/constants';
import PrivateWizardSingle from 'Routes/components/PrivateWizardSingle';
import BankSlips from 'pages/BanksSlip';
import useCan from 'hooks/useCan';
import PrivateLayouted from 'Routes/components/PrivateLayouted';
import SwitchBankAccounts from 'pages/SwitchBankAccounts';

const IncomeReport = React.lazy(() => import('pages/IncomeReport'));
const BankFullBalance = React.lazy(() => import('pages/BankFullBalance'));
const BankStatement = React.lazy(() => import('pages/BankStatement'));
const OFXBalance = React.lazy(() => import('pages/OFXBalance'));
const TransactionalPassword = React.lazy(() =>
  import('pages/TransactionalPassword')
);
const Payments = React.lazy(() => import('pages/Payments'));
const Pix = React.lazy(() => import('pages/Pix'));

const Card = React.lazy(() => import('pages/Card'));
const Transfer = React.lazy(() => import('pages/Transfer'));
const Billing = React.lazy(() => import('pages/Billing'));
const Contacts = React.lazy(() => import('pages/Contacts'));
const Payers = React.lazy(() => import('pages/Payers'));
const BankDashboard = React.lazy(() => import('pages/BankDashboard'));
const CreateBankAccountWizard = React.lazy(() =>
  import('pages/CreateBankAccountWizard')
);

export const BankRoutes = () => {
  const { canDo } = useCan();
  const canDoTransactions = canDo('read:transactions', 'bank');

  return [
    <PrivateWizardSingle
      component={CreateBankAccountWizard}
      exact
      path={PATHS.ACCOUNT.CREATE}
      key={PATHS.ACCOUNT.CREATE}
    />,
    <PrivateWizardSingle
      component={CreateBankAccountWizard}
      exact
      path={PATHS.ACCOUNT.CREATE_TO}
      key={PATHS.ACCOUNT.CREATE_TO}
    />,
    <PrivateWizardSingle
      component={CreateBankAccountWizard}
      exact
      path={PATHS.ACCOUNT.CREATE_ADD_MEMBER}
      key={PATHS.ACCOUNT.CREATE_ADD_MEMBER}
    />,
    <PrivateWizardSingle
      component={CreateBankAccountWizard}
      exact
      path={PATHS.ACCOUNT.CREATE_ADD_MEMBER_LIST}
      key={PATHS.ACCOUNT.CREATE_ADD_MEMBER_LIST}
    />,
    <PrivateWizardSingle
      component={CreateBankAccountWizard}
      exact
      path={PATHS.ACCOUNT.CREATE_ADD_DOCUMENT}
      key={PATHS.ACCOUNT.CREATE_ADD_DOCUMENT}
    />,
    <PrivateWizardSingle
      component={CreateBankAccountWizard}
      exact
      path={PATHS.ACCOUNT.CREATE_ADD_DOCUMENTS_LIST}
      key={PATHS.ACCOUNT.CREATE_ADD_DOCUMENTS_LIST}
    />,
    <PrivateWizardSingle
      component={CreateBankAccountWizard}
      exact
      path={PATHS.ACCOUNT.ACTIVATE}
      key={PATHS.ACCOUNT.ACTIVATE}
    />,
    <PrivateWizardSingle
      component={BankFullBalance}
      exact
      path={PATHS.BANK.FULL_BALANCE}
      key={PATHS.BANK.FULL_BALANCE}
    />,
    <PrivateWizardSingle
      component={BankStatement}
      exact
      path={PATHS.BANK.STATEMENT}
      key={PATHS.BANK.STATEMENT}
    />,
    <PrivateLayouted
      component={OFXBalance}
      exact
      path={PATHS.BANK.OFX}
      key={PATHS.BANK.OFX}
    />,
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Payments}
          exact
          path={PATHS.PAYMENT.PAY}
          key={PATHS.PAYMENT.PAY}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Payments}
          exact
          path={PATHS.PAYMENT.PAY_MOBILE}
          key={PATHS.PAYMENT.PAY_MOBILE}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Card}
          exact
          path={PATHS.CARD.MY_CARD}
          key={PATHS.CARD.MY_CARD}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Card}
          exact
          path={PATHS.CARD.STATUS}
          key={PATHS.CARD.STATUS}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Card}
          exact
          path={PATHS.CARD.UNLOCK}
          key={PATHS.CARD.UNLOCK}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Card}
          exact
          path={PATHS.CARD.UNLOCK_WITH_CODE}
          key={PATHS.CARD.UNLOCK_WITH_CODE}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Card}
          exact
          path={PATHS.CARD.UNLOCK_MOBILE}
          key={PATHS.CARD.UNLOCK_MOBILE}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Card}
          exact
          path={PATHS.CARD.PASSWORD}
          key={PATHS.CARD.PASSWORD}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Card}
          exact
          path={PATHS.CARD.REQUEST}
          key={PATHS.CARD.REQUEST}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateLayouted
          component={Billing}
          exact
          path={PATHS.BILLING.MANAGEMENT}
          key={PATHS.BILLING.MANAGEMENT}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Billing}
          exact
          path={PATHS.BILLING.CREATE}
          key={PATHS.BILLING.CREATE}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Pix}
          exact
          path={PATHS.PIX.AREA}
          key={PATHS.PIX.AREA}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Pix}
          exact
          path={PATHS.PIX.KEYS.LIST}
          key={PATHS.PIX.KEYS.LIST}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Pix}
          exact
          path={PATHS.PIX.KEYS.REGISTER}
          key={PATHS.PIX.KEYS.REGISTER}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Pix}
          exact
          path={PATHS.PIX.TRANSFER}
          key={PATHS.PIX.TRANSFER}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Pix}
          exact
          path={PATHS.PIX.TRANSFER_CODE}
          key={PATHS.PIX.TRANSFER_CODE}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Pix}
          exact
          path={PATHS.PIX.TRANSFER_QRCODE}
          key={PATHS.PIX.TRANSFER_QRCODE}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Transfer}
          exact
          path={PATHS.TRANSFER.SEND}
          key={PATHS.TRANSFER.SEND}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={Transfer}
          exact
          path={PATHS.TRANSFER.SENDTO}
          key={PATHS.TRANSFER.SENDTO}
        />
      ),
    ],
    <PrivateWizardSingle
      component={Contacts}
      exact
      path={PATHS.CONTACTS.ADD}
      key={PATHS.CONTACTS.ADD}
    />,
    <PrivateWizardSingle
      component={Contacts}
      exact
      path={PATHS.CONTACTS.EDIT}
      key={PATHS.CONTACTS.EDIT}
    />,
    <PrivateLayouted
      component={Contacts}
      exact
      path={PATHS.CONTACTS.DETAIL}
      key={PATHS.CONTACTS.DETAIL}
    />,
    <PrivateWizardSingle
      component={Contacts}
      exact
      path={PATHS.CONTACTS.ACCOUNT.ADD}
      key={PATHS.CONTACTS.ACCOUNT.ADD}
    />,
    <PrivateWizardSingle
      component={Contacts}
      exact
      path={PATHS.CONTACTS.ACCOUNT.EDIT}
      key={PATHS.CONTACTS.ACCOUNT.EDIT}
    />,
    <PrivateLayouted
      component={Contacts}
      exact
      path={PATHS.CONTACTS.ACCOUNT.DETAIL}
      key={PATHS.CONTACTS.ACCOUNT.DETAIL}
    />,
    <PrivateLayouted
      component={Contacts}
      exact
      path={PATHS.CONTACTS.LIST}
      key={PATHS.CONTACTS.LIST}
    />,

    <PrivateWizardSingle
      component={Payers}
      exact
      path={PATHS.PAYER.ADD}
      key={PATHS.PAYER.ADD}
    />,
    <PrivateWizardSingle
      component={Payers}
      exact
      path={PATHS.PAYER.DETAILS}
      key={PATHS.PAYER.DETAILS}
    />,
    <PrivateLayouted
      component={Payers}
      exact
      path={PATHS.PAYER.LIST}
      key={PATHS.PAYER.LIST}
    />,
    <PrivateLayouted
      component={SwitchBankAccounts}
      exact
      path={PATHS.ACCOUNT.SWITCH_BANK_ACCOUNTS}
      key={PATHS.ACCOUNT.SWITCH_BANK_ACCOUNTS}
    />,
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={BankSlips}
          exact
          path={PATHS.BANKSLIP.SEND}
          key={PATHS.BANKSLIP.SEND}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateWizardSingle
          component={BankSlips}
          exact
          path={PATHS.BANKSLIP.SENDTO}
          key={PATHS.BANKSLIP.SENDTO}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateLayouted
          component={BankSlips}
          exact
          path={PATHS.BANKSLIP.DETAILS}
          key={PATHS.BANKSLIP.DETAILS}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateLayouted
          component={BankSlips}
          exact
          path={PATHS.BANKSLIP.LIST}
          key={PATHS.BANKSLIP.LIST}
        />
      ),
    ],
    ...[
      canDoTransactions && (
        <PrivateLayouted
          component={IncomeReport}
          exact
          path={PATHS.BANK.INCOME_REPORT}
          key={PATHS.BANK.INCOME_REPORT}
        />
      ),
    ],
    <PrivateWizardSingle
      component={TransactionalPassword}
      exact
      path={PATHS.TRANSACTIONAL_PASSWORD.ADD}
      key={PATHS.TRANSACTIONAL_PASSWORD.ADD}
    />,
    <PrivateWizardSingle
      component={TransactionalPassword}
      exact
      path={PATHS.TRANSACTIONAL_PASSWORD.ADDTO}
      key={PATHS.TRANSACTIONAL_PASSWORD.ADDTO}
    />,
    <PrivateWizardSingle
      component={BankDashboard}
      exact
      path={PATHS.BANK.DASHBOARD}
      key={PATHS.BANK.DASHBOARD}
    />,
  ];
};
