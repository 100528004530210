import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useUserService from 'services/user';

import { getData, setData } from 'services/store';
import useToken from 'hooks/useToken';
import useConstants from 'services/contants';
import { useDispatch } from 'react-redux';
import {
  setAccountIdState,
  setUserIdState,
} from 'store/features/authorization/authorization-slice';

const UserAuthorizationContext = React.createContext({
  isAdmin: false,
  userAuthorization: null,
  loadUserAuthorization: () => {},
  loading: true,
  error: false,
  success: false,
  getCurrentID: () => ({}),
  setCurrentID: (id) => {},
  getBankAccount: () => ({}),
  getBusinessAgent: () => ({}),
  getAgentData: (id) => ({} || null),
  getAccountData: (id) => ({} || null),
  setBankAccount: (id) => {},
  hasBankAccount: () => true || false,
  hasBusinessAgent: () => true || false,
  currentAccountId: null,
  currentUserId: null,
});

export const UserAuthorizationProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [userAuthorization, setUserAuthorization] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const [fetchState, setFetchState] = useState();
  const { STORAGE_LABELS } = useConstants();
  const token = useToken();
  const { getAuth } = useUserService();

  const initial = fetchState === 'initial';
  const error = fetchState === 'error';
  const success = fetchState === 'success';
  const loading = fetchState === 'loading';

  useEffect(() => {
    let mounted = true;
    const fetchUserAuthorization = async () => {
      try {
        setFetchState('loading');
        const authorization = await getAuth(token);

        if (authorization && authorization.data) {
          setUserAuthorization({ ...authorization.data });
        } else {
          setUserAuthorization(true);
        }
        setFetchState('success');
      } catch (e) {
        setFetchState('error');
        if (e) {
          console.error('error authorization', e.message);
        }
        setUserAuthorization({ error: true });
      }
    };

    if (mounted && initial && token) {
      fetchUserAuthorization();
    }
    return () => (mounted = false);
  }, [token, getAuth, loading, initial]);

  const firstBanksWithCPF = (banks = []) =>
    banks.find((bank) => bank?.current_identity?.length === 11);

  const getBankAccount = useCallback(() => {
    const bank =
      userAuthorization?.banks && firstBanksWithCPF(userAuthorization.banks);

    return currentAccountId || bank?.current_identity;
  }, [currentAccountId, userAuthorization]);

  const getBusinessAgent = useCallback(() => {
    return (
      currentUserId ||
      (userAuthorization &&
        userAuthorization.business_agents &&
        userAuthorization.business_agents[0] &&
        userAuthorization.business_agents[0].current_identity)
    );
  }, [currentUserId, userAuthorization]);

  const setBankAccount = (id) => {
    setCurrentAccountId(id);
    saveAccount(id);
    dispatch(setAccountIdState(id));
  };

  const saveAccount = useCallback(
    (id) => {
      let key = 0;

      if (userAuthorization && userAuthorization.banks) {
        [...userAuthorization.banks].map((acc, i) => {
          if (acc.current_identity === id) key = i;
          return acc;
        });
      }
      setData(STORAGE_LABELS.CAC, key);
    },
    [userAuthorization, STORAGE_LABELS]
  );

  const getAgentData = useCallback(
    (id) => {
      if (userAuthorization && userAuthorization.business_agents) {
        return [...userAuthorization.business_agents]
          .filter((acc) => acc.current_identity === id)
          .pop();
      }
      return null;
    },
    [userAuthorization]
  );

  const getAccountData = useCallback(
    (id) => {
      if (userAuthorization && userAuthorization.banks) {
        return [...userAuthorization.banks]
          .filter((acc) => acc.current_identity === id)
          .pop();
      }
      return null;
    },
    [userAuthorization]
  );

  const saveAgent = useCallback(
    (id) => {
      let key = 0;

      if (userAuthorization && userAuthorization.business_agents) {
        [...userAuthorization.business_agents].map((acc, i) => {
          if (acc.current_identity === id) key = i;
          return acc;
        });
      }
      setData(STORAGE_LABELS.CBA, key);
    },
    [userAuthorization, STORAGE_LABELS]
  );

  const setCurrentID = useCallback(
    (id) => {
      setCurrentUserId(id);
      saveAgent(id);
      dispatch(setUserIdState(id));
    },
    [saveAgent, dispatch]
  );

  useEffect(() => {
    let mounted = true;
    let posBa = getData(STORAGE_LABELS.CBA) || 0;

    posBa = userAuthorization?.business_agents?.length < posBa ? 0 : posBa;

    if (
      mounted &&
      userAuthorization &&
      userAuthorization.business_agents &&
      userAuthorization.business_agents[posBa] &&
      userAuthorization.business_agents[posBa].current_identity
    ) {
      dispatch(
        setUserIdState(
          userAuthorization.business_agents[posBa].current_identity
        )
      );
      setCurrentID(userAuthorization.business_agents[posBa].current_identity);
    }
    let posBk = getData(STORAGE_LABELS.CAC) || 0;

    posBk = userAuthorization?.banks?.length < posBk ? 0 : posBk;

    if (
      mounted &&
      userAuthorization &&
      userAuthorization.banks &&
      userAuthorization.banks[posBk] &&
      userAuthorization.banks[posBk].current_identity
    ) {
      dispatch(
        setAccountIdState(userAuthorization.banks[posBk].current_identity)
      );
      setCurrentAccountId(userAuthorization.banks[posBk].current_identity);
      // Sentry.setAccountId(userAuthorization.banks[posBk].current_identity);
    }

    return () => (mounted = false);
  }, [userAuthorization, setCurrentID, dispatch, STORAGE_LABELS]);

  const getCurrentID = useCallback(() => {
    return (
      currentUserId ||
      (userAuthorization &&
        userAuthorization.business_agents &&
        userAuthorization.business_agents[0] &&
        userAuthorization.business_agents[0].current_identity)
    );
  }, [currentUserId, userAuthorization]);

  const hasBankAccount = useMemo(
    () =>
      userAuthorization &&
      userAuthorization.banks &&
      userAuthorization.banks.length > 0,
    [userAuthorization]
  );

  const hasBusinessAgent = useMemo(
    () =>
      userAuthorization &&
      userAuthorization.business_agents &&
      userAuthorization.business_agents.length > 0,
    [userAuthorization]
  );

  const isAdmin = () => getAgentData(getCurrentID())?.is_admin;

  const loadUserAuthorization = (force) => {
    if (force) setUserAuthorization(false);
    setFetchState('initial');
  };

  return (
    <UserAuthorizationContext.Provider
      value={{
        isAdmin,
        success,
        error,
        loading,
        userAuthorization,
        loadUserAuthorization,
        getCurrentID,
        setCurrentID,
        getBankAccount,
        getBusinessAgent,
        getAgentData,
        getAccountData,
        setBankAccount,
        hasBankAccount,
        hasBusinessAgent,
        currentAccountId,
        currentUserId,
      }}
    >
      {children}
    </UserAuthorizationContext.Provider>
  );
};

export default UserAuthorizationContext;
